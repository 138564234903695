<template>
  <div class="editNotification">
    <el-button type="primary"
               @click="sendNotice(0)">发送
    </el-button>
    <el-button type="info"
               @click="sendNotice(1)">存草稿
    </el-button>
    <el-form v-model="noticeForm"
             class="noticeForm-box"
             ref="noticeForm">
      <el-form-item label="接收人：">
        <el-tag v-for="(receiver,index) in receiverList"
                closable
                @close="receiverClose(index)"
                :key="receiver.key">{{receiver.label}}
        </el-tag>
        <el-button icon="el-icon-plus"
                   size="small"
                   @click="receiverClick"></el-button>
      </el-form-item>
      <el-form-item label="提交资料人：">
        <el-tag v-for="dataReceiver,index in dataReceiverList"
                closable
                type="success"
                @close="dataReceiverClose(index)"
                :key="dataReceiver.id">{{dataReceiver.label}}
        </el-tag>
        <el-button icon="el-icon-plus"
                   size="small"
                   @click="dataReceiverClick"></el-button>
      </el-form-item>
      <el-form-item label="附件：">
        <el-upload action="/api/base/uploadByOss.do"
                   :headers="{Authorization:token}"
                   :limit="1"
                   :on-remove="onRemoveFile"
                   :on-success="enclosureUploadSuccess"
                   :data="{path:'editNotification',name:''}"
                   :show-file-list="true">
          <el-button type="primary"
                     icon='el-icon-upload2'>导 入</el-button>
        </el-upload>
        <el-button type="primary"
                   class="load-file"
                   @click="loadFile"
                   v-if="noticeForm.file">下载附件</el-button>
      </el-form-item>
      <el-form-item label="主题：">
        <el-input placeholder="请输入主题"
                  v-model="noticeForm.title"
                  class="input-box"></el-input>
      </el-form-item>
      <el-form-item label="内容：">
        <el-input type="textarea"
                  class="input-box"
                  placeholder="请输入内容"
                  :autosize="{ minRows: 12, maxRows: 20 }"
                  v-model="noticeForm.content"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <span>发件人：{{userInfo.username}}</span>
    </div>
    <el-dialog :visible.sync="receiverVisible"
               title="请选择接收人"
               width="600px">
      <!--expand-on-click-node 点击节点展开或收缩
      default-checked-keys默认绑定的节点
      default-expanded-keys 默认展开的节点-->
      <el-tree :data="teacherList"
               expand-on-click-node
               class="tree-box"
               :default-expanded-keys="defaultExpandedKeys"
               :default-checked-keys="defaultCheckedKeys"
               ref="receiverTree"
               @check="receiverCheck"
               accordion
               node-key="key"
               show-checkbox></el-tree>
      <el-button @click="receiverVisible=false">取 消</el-button>
      <el-button type="primary"
                 @click="receiverConfirm">确 定
      </el-button>
    </el-dialog>
    <el-dialog :visible.sync="dataReceiverVisible"
               title="请选择提交资料人"
               width="600px">
      <el-checkbox :indeterminate="isIndeterminate"
                   v-model="checkAll"
                   @change="handleCheckAllChange">全选
      </el-checkbox>
      <el-checkbox-group v-model="newDataReceiverList"
                         @change="handleCheckedCitiesChange">
        <el-checkbox v-for="dataReceiver in receiverList"
                     :label="dataReceiver"
                     :key="dataReceiver.id">{{dataReceiver.label}}
        </el-checkbox>
      </el-checkbox-group>
      <el-button @click="dataReceiverVisible=false">取消</el-button>
      <el-button type="primary"
                 @click="dataReceiverConfirm">确定
      </el-button>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "editNotification",
  data() {
    return {
      noticeForm: {
        title: "",
        content: "",
        type: "",
        file: "",
      },
      receiverVisible: false,
      dataReceiverVisible: false,
      teacherList: [], //所有教师列表
      defaultExpandedKeys: [], //打开接收人层数
      receiverList: [], //接收人列表
      currentReceiverList: [], //暂时选择的接收人
      dataReceiverList: [], //提交资料人列表
      newDataReceiverList: [], //绑定选中的提交资料人
      checkAll: false,
      isIndeterminate: true,
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      currentStatus: 0, //当前状态是草稿还是创建通知,草稿1
      defaultCheckedKeys: [], //绑定已有接收人
      token: sessionStorage.getItem("token"),
    };
  },
  created() {
    this.loadTeacherList();
    if (this.$route.query.status) {
      this.currentStatus = this.$route.query.status;
      this.$post("/base/queryNoticeById.do", { id: this.$route.query.noticeId })
        .then((res) => {
          this.noticeForm.title = res.data.title;
          this.noticeForm.content = res.data.content;
          this.noticeForm.file = res.data.file;
          this.receiverList = res.data.list1;
          this.currentReceiverList = res.data.list1;
          res.data.list1.forEach((data) => {
            this.defaultCheckedKeys.push(data.key);
          });
          this.dataReceiverList = res.data.list2;
          this.newDataReceiverList = res.data.list2;
        })
        .catch((err) => {
          this.$message({ type: "warning", message: err.message });
        });
    }
  },
  methods: {
    sendNotice(status) {
      const that = this;
      let receiverId = [];
      this.receiverList.forEach((receiver) => {
        receiverId.push(receiver.id);
      });
      let receiverIds = receiverId.join(",");
      let dataReceiverId = [];
      this.dataReceiverList.forEach((dataReceiver) => {
        dataReceiverId.push(dataReceiver.id);
      });
      let dataReceiverIds = dataReceiverId.join(",");
      let data = {
        title: this.noticeForm.title,
        content: this.noticeForm.content,
        teachers: receiverIds,
        file: this.noticeForm.file,
        status,
        needTeachers: dataReceiverIds,
      };
      let apiUrl = "/base/insertNotice.do";
      if (this.currentStatus === 1) {
        apiUrl = "/base/insertNoticeByDraft.do";
        data.id = this.$route.query.noticeId;
      }
      this.$confirm("是否确定该操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post(apiUrl, data)
            .then((res) => {
              that.$message({
                type: "success",
                message: res.message,
              });
              that.$router.push({
                name: "notificationManagement",
                query: {
                  type: status,
                },
              });
            })
            .catch((err) => {
              that.$message({
                type: "warning",
                message: err.message,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消该操作",
          });
        });
    },
    loadTeacherList() {
      const that = this;
      this.$post("/media/queryBookByTea.do", {})
        .then((res) => {
          res.data[0].children.forEach((department) => {
            this.defaultExpandedKeys.push(department.key);
          });
          this.teacherList = res.data;
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    // 点击接收人弹框
    receiverClick() {
      this.receiverVisible = true;
      this.$nextTick(() => {
        this.$refs.receiverTree.setCheckedKeys(this.defaultCheckedKeys);
        // console.log(this.$refs.receiverTree.getCheckedKeys());
        this.$refs.receiverTree.setCheckedNodes(this.currentReceiverList);
      });
    },
    // 点击选择接收人
    receiverCheck(row, rowArray) {
      //row当前勾选的对象,rowArray获得所有勾选的信息,this.$refs.receiverTree.getCheckedKeys()获取所有勾选的key值，this.$refs.receiverTree.getCheckedNodes()获取所有勾选的对象
      this.currentReceiverList = [];
      rowArray.checkedNodes.forEach((checked) => {
        if (checked.type === 1) {
          this.currentReceiverList.push(checked);
        }
      });
    },
    // 点击提交资料人弹框
    dataReceiverClick() {
      console.log(this.newDataReceiverList);
      if (this.receiverList.length !== 0) {
        this.dataReceiverVisible = true;
      } else {
        this.$message({
          type: "warning",
          message: "请先选择接收人",
        });
      }
    },
    // 确定接收人
    receiverConfirm() {
      //   this.$nextTick(() => {
      //     this.currentReceiverList = [];
      //     this.currentReceiverList = this.$refs.receiverTree.getCheckedNodes();
      //   });
      this.receiverList = this.filterRepeat(this.currentReceiverList);
      this.currentReceiverList = this.receiverList;
      this.receiverVisible = false;
    },
    //数组对象去重
    filterRepeat(arr) {
      arr = arr.filter((element, index, self) => {
        return self.findIndex((el) => el.id === element.id) === index;
      });
      return arr;
    },
    // 确定提交资料人
    dataReceiverConfirm() {
      this.dataReceiverList = this.newDataReceiverList;
      this.dataReceiverVisible = false;
    },
    // 提交资料人全选
    handleCheckAllChange(val) {
      let newReceiverList = [];
      this.receiverList.forEach((receiver) => {
        newReceiverList.push(receiver);
      });
      this.newDataReceiverList = val ? newReceiverList : [];
      this.isIndeterminate = false;
    },
    // 资料接收人点击选择
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.receiverList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.receiverList.length;
    },
    // 删除接收人
    receiverClose(index) {
      this.receiverList.splice(index, 1);
      this.currentReceiverList = this.receiverList;
    },
    // 删除提交资料人
    dataReceiverClose(index) {
      this.dataReceiverList.splice(index, 1);
      this.newDataReceiverList = this.dataReceiverList;
    },
    enclosureUploadSuccess(res, file) {
      this.noticeForm.file = file.response.message;
    },
    loadFile() {
      window.open(this.$fileUploadDomain + this.noticeForm.file);
    },
    // 文件移除的钩子
    onRemoveFile(res, file) {
      this.noticeForm.file = "";
    },
  },
};
</script>

<style scoped lang="scss">
.input-box {
  width: 80%;
}

.noticeForm-box {
  margin-top: 20px;
}

/deep/ .tree-box {
  height: 500px;
  overflow-y: auto;
  .el-tree-node {
    .is-leaf + .el-checkbox .el-checkbox__inner {
      display: inline-block;
    }

    .el-checkbox_input .el-checkbox__inner {
      display: none;
    }
  }
}

/deep/ .el-tag {
  margin-right: 10px;
}
.load-file {
  margin: 10px 0 0 54px;
}
</style>
